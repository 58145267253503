<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col md="4">
          <b-form-group label-for="userGroup">
            <template v-slot:label>
              {{ $t("sessionTime") }}
            </template>
            <br />
            <number-input
              v-model="userData.sessionTime"
              center
              controls
              :min="1"
              :max="60"
            />
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updateSession()"
          >
            {{ $t("message.buttons.save") }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import getHeader from "@core/utils/auth";
import URL_API from "@core/utils/env";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      userData: store.state.user.userData,
    };
  },

  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },

  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },

    updateSession() {
      if (this.userData.sessionTime > 1) {
        axios({
          method: "put",
          url: `${URL_API}user/sessionTime/${this.userData.sessionTime}`,
          data: this.userData,
          headers: getHeader(this.userData),
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("redirectMessage"),
            },
          });
          this.logoutTimer();
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Não permitido",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: this.$i18n.t("biggerThanOne"),
          },
        });
      }
    },

    logoutTimer() {
      setTimeout(() => {
        this.logout();
      }, 3000);
    },

    async logout() {
      await axios({
        method: "post",
        url: `${URL_API}auth/logout`,
        headers: getHeader(this.userData),
      }).then((response) => {

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
  
        localStorage.removeItem("userData");
        localStorage.removeItem("employeeData");
  
        this.$ability.update(initialAbility);
  
        this.$router.push({ name: "auth-login" });
      })

    },
  },
};
</script>
