<template>
  <b-card>
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            size="120px"
            v-bind:src="'data:image/jpeg;base64,' + employeeData.data.avatar"
            style="cursor: default"
            v-if="avatar === ''"
          />
          <b-avatar size="120px" :src="avatar" style="cursor: default" v-else />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-avatar
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          v-if="restrictions('user_file_upload')"
        >
          {{ $t("message.buttons.update") }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
        />

        <b-card-text
          >{{ $t("onlyImages") }}<b> .jpg </b
          >{{ $t("allowImages") }}</b-card-text
        >
      </b-media-body>
    </b-media>

    <b-modal
      id="modal-avatar"
      ok-title="Selecionar"
      cancel-title="Cancelar"
      centered
      title="Atualizar Imagem"
      @ok="updateAvatar"
    >
      <div>
        <croppa v-if="visibleComponent === 'croppa'" id="croppa" />
      </div>
    </b-modal>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group label-for="contract">
            <b-form-checkbox
              switch
              inline
              style="margin-top: 2px"
              v-model="userData.reminder"
              @input="updateReminder()"
            />
            <template v-slot:label>
              {{ $t("reminder_show") }}
            </template>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label-for="account-username">
            <b-form-input
              v-model="employeeData.data.fullName"
              placeholder="Username"
              name="username"
              disabled
            />
            <template v-slot:label>
              {{ $t("message.list.name") }}
            </template>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label-for="account-e-mail">
            <b-form-input
              v-model="userData.email"
              name="email"
              placeholder="Email"
              disabled
            />

            <template v-slot:label>
              {{ $t("message.tableHeader.email") }}
            </template>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label-for="projectRole">
            <b-form-input
              :value="userStatus(employeeData.data.projectRole)"
              name="projectRole"
              disabled
            />
            <template v-slot:label>
              {{ $t("message.employees.function") }}
            </template>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label-for="contract">
            <b-form-input
              v-model="employeeData.data.type"
              name="contract"
              disabled
            />
            <template v-slot:label>
              {{ $t("message.employees.contract") }}
            </template>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="addFile()"
          >
            {{ $t("message.buttons.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BFormFile,
  BButton,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BModal,
  VBModal,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Croppa from "./components/Croppa.vue";
import getHeader from "@core/utils/auth";
import URL_API from "@core/utils/env";
import store from "@/store";
import getHeaderFile from "@core/utils/authFile";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    Croppa,
    BModal,
    BAvatar,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: store.state.user.userData,
      employeeData: store.state.user.employeeData,
      userRole: "",
      employee: null,
      fullName: "",
      projectRole: "",
      user: null,
      avatar: "",
      email: "",
      visibleComponent: "croppa",
      contract: "",
      file: null,
    };
  },
  created() {
    //Para chamar avatar em Croppa
    this.$root.$refs.AccountSettingGeneral = this;
  },
  methods: {
    getAvatar() {
      return this.avatar;
    },

    restrictions(value) {
      return AccessControl(value);
    },

    async updateAvatar() {
      this.avatar = await this.$root.$refs.Croppa.generateImage();
      this.file = await this.$root.$refs.Croppa.generateFile();
      this.$root.$emit("updateAvatar", this.avatar);
    },

    async updateReminder() {
      axios({
        method: "put",
        url: `${URL_API}user/reminder/${this.userData.id}`,
        headers: getHeader(this.userData),
        data: this.userData,
      }).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.updated"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    async addFile() {
      if (this.file != null) {
        axios
          .post(`${URL_API}file/avatar/${this.userData.id}/avatar`, this.file, {
            headers: getHeaderFile(this.userData),
          })
          .then((response) => {
            if (!response.data.id && response.data != "") {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("noDataAvailable"),
                  icon: "XIcon",
                  variant: "danger",
                  text: this.$i18n.t("noDataAvailableDesc"),
                },
              });
            } else if (response.data === "") {
              this.file = null;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("Error"),
                  icon: "XIcon",
                  variant: "warning",
                  text: this.$i18n.t("offline"),
                },
              });
            } else {
              this.updateUser(response.data);
            }
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("noChange"),
            icon: "CheckIcon",
            variant: "warning",
            text: this.$i18n.t("noChangeMessage"),
          },
        });
      }
    },

    updateUser(data) {
      axios({
        method: "put",
        url: `${URL_API}user/avatar/${this.userData.id}/${data.path}/${data.fileId}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("redirectMessage"),
            },
          });
        })
        .then(() => {
          this.logoutTimer();
        });
    },

    logoutTimer() {
      setTimeout(() => {
        this.logout();
      }, 3000);
    },

    logout() {
      this.$router.push({ name: "auth-login" });
    },

    userStatus(value) {
      if (value == "PROGRAMMER") {
        return "Programador";
      } else if (value == "TESTER") {
        return "Testador";
      } else if (value == "MANAGER") {
        return "Gerente";
      } else if (value == "ANALYST") {
        return "Analista";
      } else if (value == "ARCHITECT") {
        return "Arquiteto";
      } else if (value == "TRAINEE") {
        return "Estágiario";
      } else if (value == "UNDEFINED") {
        return "Indefinido";
      } else {
        return "";
      }
    },
  },

  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style>
#croppa {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
