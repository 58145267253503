<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="userData.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Nova Senha"
                @keyup.enter="updatePassword()"
              />

              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updatePassword()"
          >
            {{ $t("message.buttons.save") }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import getHeader from "@core/utils/auth";
import URL_API from "@core/utils/env";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      userData: store.state.user.userData,
    };
  },

  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },

  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },

    updatePassword() {
      const payload = {
        password: this.userData.password
      }

      if (this.userData.password != "") {
        axios({
          method: "patch",
          url: `${URL_API}user/password/${this.userData.id}`,
          data: payload,
          headers: getHeader(this.userData),
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.saved"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("redirectMessage"),
              },
            });
          })
          .then(() => {
            this.logoutTimer();
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("notPassword"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: this.$i18n.t("invalidPassword"),
          },
        });
      }
    },

    logoutTimer() {
      setTimeout(() => {
        this.logout();
      }, 3000);
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      localStorage.removeItem("userData");
      localStorage.removeItem("employeeData");

      this.$ability.update(initialAbility);

      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
